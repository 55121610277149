<template>
    <div id="image">
      <vue-headful
      title="Error"/>
      <div class="container-fluid mt-5 justify-content-center">
        <h1 id="error">
         ❌ Esta página no existe ❌
        </h1>
      </div>
      <router-link class="return" to="/">⬅ Volver al inicio</router-link>
  
    </div>   
</template>

<script>
export default {
    name: 'Error'
}
</script>

<style scoped>

#image {
  background-image: url("../assets/404.png");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  background-color: black;
}
#error {
 color: red; 
 font-size: 45px; 
 margin: 0% auto;
 text-align: center;
 font-family: 'Titan One';   
 text-shadow: 1px 2px 1px  rgba(0,0,0,.5);
 padding-top: 40px;
 padding-bottom: 30px;
}
#error:hover {
 text-shadow: 2px 2px 20px  #2c90c6, rgb(158, 166, 56) 0 -1px 2px;
}
.return {
 font-size: 35px;
 display: flex;
 position: fixed;
 margin-top: 600px;
 margin-left: 750px;
}
</style>